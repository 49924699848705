<template>
  <div class="page">
    <b-card>
      <div class="custom-search d-flex flex-justify-between flex-wrap">
        <!-- <b-button
          variant="outline-primary"
          class="col-lg-2 col-md-4 col-sm-12 col-xs-12 mb-2"
          @click="$router.push({name: 'Create Ticket'})"
        >
          <feather-icon icon="PlusIcon" />
          Add New Ticket
        </b-button> -->
        <div>

          <b-form-group class="float-right">
            <div class="d-flex align-items-center">
              <b-form-input
                v-model="search"
                placeholder="Search"
                type="text"
                class="d-inline-block"
              />
            </div>
          </b-form-group>
          <b-form-group class="float-right mr-1">
            <div class="d-flex align-items-center">
              <v-select
                id="status"
                v-model="status"
                name="status"
                label="text"
                :options="statusOptions"
                :clearable="false"
                :searchable="false"
              />
            </div>
          </b-form-group>
          <div class="clear-both" />
        </div>
      </div>
      <vue-good-table
        :columns="columns"
        :rows="getTickets"
        :search-options="{
          enabled: true,
          externalQuery: search }"
        :pagination-options="{
          enabled: true,
          perPage:pageLength
        }"
      >
        <template
          slot="table-row"
          slot-scope="props"
        >

          <!-- Column: Ticket ID -->
          <!-- <span
            v-if="props.column.field === 'ticketID'"
            class="text-nowrap"
          >
            <span class="text-nowrap">
              <router-link
                :to="'tickets/' + props.row.ticketID"
              >{{ props.row.ticketID }}</router-link>
            </span>
          </span> -->
          <!-- Column: Name -->
          <span
            v-if="props.column.field === 'name'"
            class="text-nowrap"
          >
            <span class="text-nowrap">{{ props.row.name }}</span>
          </span>
          <span
            v-else-if="props.column.field === 'ticketID'"
            class="text-nowrap"
          >
            <router-link
              :to="'tickets/' + props.row.id"
              class="text-nowrap"
            >{{ props.row.ticketID }}</router-link>
          </span>
          <!-- Column: Status -->
          <span v-else-if="props.column.field === 'priority'">
            <b-badge
              class="text-capitalize"
              :variant="getVariant(props.row.priority)"
            >
              {{ props.row.priority }}
            </b-badge>
          </span>
          <!-- Column: Status -->
          <span v-else-if="props.column.field === 'status'">
            <b-badge
              class="text-capitalize"
              :variant="getVariant(props.row.status)"
            >
              {{ props.row.status }}
            </b-badge>
          </span>
          <!-- Column: Action -->
          <span v-else-if="props.column.field === 'action'">
            <span>
              <b-dropdown
                variant="link"
                toggle-class="text-decoration-none"
                no-caret
              >
                <template v-slot:button-content>
                  <feather-icon
                    icon="MoreVerticalIcon"
                    size="16"
                    class="text-body align-middle mr-25"
                  />
                </template>
                <b-dropdown-item :to="'tickets/' + props.row.id">
                  <feather-icon
                    icon="Edit2Icon"
                    class="mr-50"
                  />
                  <span>Edit</span>
                </b-dropdown-item>
                <b-dropdown-item
                  v-if="props.row.status !== 'Solved'"
                  v-b-modal.close-ticket-modal
                  @click="target = props.row"
                >
                  <feather-icon
                    icon="TrashIcon"
                    class="mr-50"
                  />
                  <span>Close</span>
                </b-dropdown-item>
              </b-dropdown>
            </span>
          </span>

          <!-- Column: Common -->
          <span v-else>
            {{ props.formattedRow[props.column.field] }}
          </span>
        </template>

        <!-- pagination -->
        <template
          slot="pagination-bottom"
          slot-scope="props"
        >
          <div class="d-flex justify-content-between flex-wrap">
            <div class="d-flex align-items-center mb-0 mt-1">
              <span class="text-nowrap ">
                Showing 1 to
              </span>
              <b-form-select
                v-model="pageLength"
                :options="['3','5','10']"
                class="mx-1"
                @input="(value)=>props.perPageChanged({currentPerPage:value})"
              />
              <span class="text-nowrap"> of {{ props.total }} entries </span>
            </div>
            <div>
              <b-pagination
                :value="1"
                :total-rows="props.total"
                :per-page="pageLength"
                first-number
                last-number
                align="right"
                prev-class="prev-item"
                next-class="next-item"
                class="mt-1 mb-0"
                @input="(value)=>props.pageChanged({currentPage:value})"
              >
                <template #prev-text>
                  <feather-icon
                    icon="ChevronLeftIcon"
                    size="18"
                  />
                </template>
                <template #next-text>
                  <feather-icon
                    icon="ChevronRightIcon"
                    size="18"
                  />
                </template>
              </b-pagination>
            </div>
          </div>
        </template>
      </vue-good-table>

      <b-modal
        id="close-ticket-modal"
        title="Closing the ticket"
        centered
        cancel-variant="outline-danger"
        ok-variant="outline-primary"
        @ok="onDialogOK"
        @cancel="target = {}"
      >
        <p>
          Close <b>{{ target.ticketID }}</b>?
        </p>
      </b-modal>
      <!-- <b-table
        responsive="sm"
        :items="tickets"
      >
        <template #cell(ticketID)="data">
          <router-link :to="'/support/' + data.item.id">
            {{ data.value }}
          </router-link>
        </template>
        <template #cell(status)="data">
          <b-badge
            pill
            :variant="getVarient(data.value)"
          >
            {{ data.value }}
          </b-badge>
        </template>
      </b-table> -->
    </b-card>
  </div>
</template>

<script>
import vSelect from 'vue-select'
import {
  BCard, BBadge, BFormGroup, BFormInput, BFormSelect, BPagination, BDropdownItem, BDropdown, BModal,
} from 'bootstrap-vue'
import { VueGoodTable } from 'vue-good-table'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import 'vue-good-table/dist/vue-good-table.css'

export default {
  components: {
    BCard, BBadge, BFormGroup, BFormInput, VueGoodTable, BFormSelect, BPagination, BDropdownItem, BDropdown, vSelect, BModal,
  },
  data() {
    return {
      status: 'All',
      statusOptions: ['All', 'Open', 'In Progress', 'New Comment', 'Solved'],
      fields: ['ticketID', 'title', 'category', 'priority', 'status', 'updated', 'created'],
      tickets: [],
      search: '',
      pageLength: 10,
      dir: false,
      rows: [],
      target: {},
      columns: [
        {
          label: 'Ticket ID',
          field: 'ticketID',
        },
        {
          label: 'Name',
          field: 'name',
        },
        {
          label: 'Email',
          field: 'email',
        },
        {
          label: 'Title',
          field: 'title',
        },
        {
          label: 'Category',
          field: 'category',
        },
        {
          label: 'Priority',
          field: 'priority',
        },
        {
          label: 'Status',
          field: 'status',
        },
        {
          label: 'Updated',
          field: 'updated',
        },
        {
          label: 'Created',
          field: 'created',
        },
        {
          label: 'Action',
          field: 'action',
        },
      ],
    }
  },
  computed: {
    // statusVariant() {
    //   const statusColor = {
    //     Solved: 'light-success',
    //   }

    //   return status => statusColor[status]
    // },
    getTickets() {
      let temp = this.tickets

      if (this.status !== '' && this.status !== 'All') {
        temp = this.tickets.filter(item => item.status === this.status)
      }
      return temp
    },
  },
  created() {
    this.$store.dispatch('superadmin/getAllTickets').then(response => {
      if (response.code === 200) {
        console.log(response)
        this.tickets = response.data.map(data => {
          return {
            id: data.id,
            ticketID: data.support_ticket_id,
            name: data.user && data.user.name ? data.user.name : '',
            email: data.user && data.user.email ? data.user.email : '',
            title: data.title,
            category: data.support_category.subcategory_name,
            priority: data.priority,
            status: data.status,
            updated: this.formatDate(data.updated_at),
            created: this.formatDate(data.created_at),
          }
        })

        console.log(this.tickets)
      }
    }).catch(err => {
      console.error(err)
    })
  },
  methods: {
    formatDate(date) {
      return new Date(date).toLocaleString('en-CA')
    },
    getVariant(status) {
      console.log(status)
      switch (status) {
        case 'Active': return 'primary'
        case 'Solved': return 'success'
        case 'Open': return 'primary'
        case 'Closed': return 'danger'
        case 'critical': return 'danger'
        case 'moderate': return 'warning'
        case 'In Progress': return 'warning'
        case 'New Comment': return 'primary'
        default: return 'dark'
      }
    },
    onDialogOK() {
      this.$store.dispatch('superadmin/closeTicket', this.target.id).then(response => {
        if (response.code === 200) {
          this.showToast('Success', 'CheckCircleIcon', 'Ticket ' + this.target.ticketID + ' has been closed', 'success', 'bottom-right')
          const ele = this.tickets.find(element => element.id === this.target.id)

          ele.status = response.data.status
          this.target = {}
        }
      }).catch(err => {
        console.error(err)
      })
    },
    showToast(title, icon, text, variant, position) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title,
          icon,
          text,
          variant,
        },
      },
      {
        position,
      })
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
.vs__dropdown-toggle{
  min-width:100px;
}
</style>
